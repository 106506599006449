import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActionDetailService {
  private actionDetailSubject = new BehaviorSubject<string>('');
  actionDetail$ = this.actionDetailSubject.asObservable();

  setActionDetail(detail: string) {
    this.actionDetailSubject.next(detail);
  }

  getActionDetail(): string {
    return this.actionDetailSubject.value;
  }
  constructor() { }
}
